<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="reason pa-5">
            <h2 class="mb-4">
                Please select a reason to dismiss this alert
            </h2>
            <div class="mb-5">
                <p>
                    To dismiss this alert, please select a reason from the dropdown menu.
                    If none of the listed reasons apply, choose 'Other' and provide a custom explanation before proceeding.
                </p>
            </div>
            <div class="reason-selector mb-4">
                <v-select
                    v-model="reason"
                    :items="reasons"
                    label="SELECT THE REASONING TO DISMISS THIS ALERT"
                    placeholder="Select"
                    class="styled-field" />
            </div>
            <div
                class="reason__textarea mb-4"
                :class="{'hidden': reason !== 'Other'}">
                <v-textarea
                    v-model="comment"
                    no-resize
                    label="ADD YOUR COMMENT"
                    placeholder="Type reason here"
                    class="styled-field" />
            </div>
            <div class="reason__button">
                <styled-button
                    :disabled="loading || disabled"
                    :loading="loading"
                    @click="dismissAlert">
                    SUBMIT
                </styled-button>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { mapState } from 'vuex';

export default {
    components: {
        StyledButton,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        alertId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            comment: '',
            reason: '',
            reasons: [
                '20% view content alert not valid',
                'Issue Resolved',
                'Duplicate Alert',
                'False Positive',
                'Already Acknowledged',
                'Budget/Spending Already Adjusted',
                'Campaign/Ad Account Paused',
                'Temporary Fluctuation',
                'Lead Volume Issue Under Review',
                'User Disconnected Intentionally',
                'Payment needs attention',
                'Temporarily hide',
                'Canceled Clients',
                'Other'
            ],
            loading: false,
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.user,
        }),
        disabled() {
            if (this.reason === '') {
                return true;
            } else if (this.reason === 'Other' && this.comment === '') {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        value(current) {
            if (current === false) {
                this.reason = '';
                this.comment = '';
            }
        }
    },
    methods: {
        async dismissAlert() {
            try {
                this.loading = true;
                const data = {
                    dismissed_by: this.currentUser.name,
                    reason: this.reason,
                    comment: this.comment
                };
                await this.$apiRepository.dismissAlert(this.alertId, data);
                this.$emit('close-and-refresh');
            } catch (error) {
                this.$flash('An error occurred while dismissing alert', 'red');
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.reason {
    .v-label--active {
        font-size: 14px;
    }
    &__selector {
        max-width: 400px;
        input {
            font-size: 14px;
        }
    }
    &__textarea textarea {
        font-size: 14px;
    }
    &__button {
        text-align: center;
    }
}
</style>